<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view v-if="isRouterAlive" :key="key" class="page-main-container" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },

  methods: {
    onReload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.app-main {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.page-main-container{
    padding: 20px 40px 40px 40px;
}
.fixed-header+.app-main {
    padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
    .fixed-header {
        padding-right: 15px;
    }
}
</style>
