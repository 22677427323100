import router from './routers'
// import store from './store'
import NProgress from 'nprogress' // 页面进度条
import 'nprogress/nprogress.css'
// import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false })

// 白名单
// const whiteList = ['/login', '/home', '/auth-redirect']

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  // 设置页面标题
  document.title = getPageTitle(to.meta.title)
  // 根据角色生成可访问路由图
  // const accessRoutes = await store.dispatch('permission/generateRoutes', [])
  // 动态添加可访问路由
  // router.addRoutes(accessRoutes)
  if (!to.path) {
    next({ path: '/home' })
    NProgress.done()
  } else {
    next()
    NProgress.done()
  }
})

router.afterEach(() => {
  // console.log('afterEach')
})
router.beforeResolve((to, from, next) => {
  // console.log('beforeResolve')
  next()
  NProgress.done()
})
