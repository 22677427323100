<template>
  <div :class="classObj" class="app-wrapper">
    <el-container>
      <el-header :height="headerHeight">
        <div :class="{'fixed-header':fixedHeader}">
          <navbar />
        </div>
      </el-header>
      <el-main>
        <!-- <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" /> -->
        <!-- <sidebar class="sidebar-container" /> -->
        <div class="main-container">
          <page-title-breadcrumb />
          <app-main />
        </div>
      </el-main>
      <el-footer :height="footerHeight">
        <div><a href="http://beian.miit.gov.cn/" class="icp-text" target="_blank">闽ICP备2020021395号-1</a></div>
        <div><img src="@/assets/logo/beian.png" class="beian-img" /><span class="icp-text">闽公网安备 35021102000870号</span></div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import { Navbar, Sidebar, AppMain, PageTitleBreadcrumb } from './components'
import { Navbar, AppMain, PageTitleBreadcrumb } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import Variables from '@/styles/variables.scss'

export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
    PageTitleBreadcrumb
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      }
    },
    headerHeight() {
      return Variables.headerHeight
    },
    footerHeight() {
      return Variables.footerHeight
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar{
        position: fixed;
        top: 0;
    }
    & .el-header-fixed{
        padding: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1002;
    }
    & .el-header{
        transition: background .2s;
        background: #fff;
        backdrop-filter: none;
        box-shadow: 0 4px 15px rgba(0,0,0,.08);
        padding: 0;
    }
    & .el-main{
        padding: 0;
        background-color: $mainBgColor;
        min-height: calc(100vh - #{$headerHeight} - #{$headerHeight});
        & .page-title{
            margin: 30px 30px 10px;
        }
    }
    & .el-footer{
        background: #252525;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icp-text {
            font-size: 14px;
        }
        .beian-img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
}
.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
}

.hideSidebar .fixed-header {
    width: calc(100% - 54px)
}

.mobile .fixed-header {
    width: 100%;
}
</style>
