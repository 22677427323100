<template>
  <div class="sidebar-logo-container">
    <transition name="sidebarLogoFade">
      <router-link key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" src="@/assets/logo/logo.png" class="sidebar-logo" />
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
const defaultSettings = require('@/settings.js')

export default {
  name: 'SidebarLogo',
  props: {
    // collapse: {
    //     type: Boolean,
    //     required: true
    // }
  },
  data() {
    return {
      title: defaultSettings.title,
      logo: './src/assets/404_images/logo.png'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    position: relative;
    // width: 340px;
    height: $headerHeight;
    line-height: $headerHeight;
    background: #FFFFFF;
    padding: 0 20px;
    overflow: hidden;
    float: left;

    & .sidebar-logo-link {
        height: 100%;
        width: 100%;

        & .sidebar-logo {
            width: 40px;
            height: 40px;
            vertical-align: middle;
            margin-right: 12px;
        }

        & .sidebar-title {
            display: inline-block;
            margin: 0;
            color: #323232;
            font-weight: 600;
            line-height: 50px;
            font-size: 22px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            vertical-align: middle;
        }
    }

    &.collapse {
        .sidebar-logo {
            margin-right: 0px;
        }
    }
}
</style>
