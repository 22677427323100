import Vue from 'vue'

import ElementUI from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import '@/styles/index.scss' // global css

import App from './App.vue'
import router from './routers'
import store from './store'

import '@/icons' // icon
import '@/permission'

import * as filters from '@/filters'

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
}

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
}

Vue.use(ElementUI)
Vue.use(CollapseTransition)

// 注册全局使用过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
