<template>
  <div class="navbar">
    <logo />
    <div class="nav-menu-container">
      <NavMenu />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/layout/components/Sidebar/Logo'
import NavMenu from './NavMenu'

export default {
  components: {
    Logo,
    NavMenu
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name'
    ])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.dialogVisible = false
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.navbar {
    height: $headerHeight;
    // overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);

    .nav-menu-container {
        height: $headerHeight;
        display: inline-block;
    }
}
</style>
