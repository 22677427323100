import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/404',
    name: 'ErrorPage',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'home' }
      }
    ]
  },
  {
    path: '/excel',
    name: 'Excel',
    component: Layout,
    redirect: '/excel/upload-excel',
    meta: { title: '人效总结', icon: 'excel' },
    alwaysShow: true,
    children: [
      {
        path: 'upload-excel',
        component: () => import('@/views/excel/upload-excel'),
        name: 'UploadExcel',
        meta: { title: '图表分析' }
      },
      {
        path: 'export-excel',
        component: () => import('@/views/excel/export-excel'),
        name: 'ExportExcel',
        meta: { title: 'Excel导出' }
      }
    ]
  },
  {
    path: '/monthly-summary',
    name: 'MonthlySum',
    component: Layout,
    redirect: '/monthly-summary/baijiahao',
    meta: { title: '月度总结', icon: 'table' },
    children: [
      {
        path: 'baijiahao',
        component: () => import('@/views/monthly-summary/baijiahao'),
        name: 'Baijiahao',
        meta: { title: '百家号' }
      },
      {
        path: 'weibo',
        component: () => import('@/views/monthly-summary/weibo'),
        name: 'Weibo',
        meta: { title: '微博' }
      }
    ]
  },
  {
    path: '/codetest',
    name: 'CodeTest',
    component: Layout,
    meta: { title: '代码测试', icon: 'debug' },
    // alwaysShow: true,
    children: [
      {
        path: 'vuex',
        name: 'VuexDemo',
        component: () => import('@/views/test-code/vuex/VuexPage'),
        meta: { title: 'vuexDemo', icon: 'vuex' }
      },
      {
        path: 'async-await',
        name: 'AsyncAwaitDemo',
        component: () => import('@/views/test-code/async-await/index'),
        meta: { title: '异步编程', icon: 'vuex' }
      },
      {
        path: 'debug',
        name: 'Debug',
        component: () => import('@/views/test-code/project-log/index'),
        meta: { title: '项目日志' }
      }
    ]
  },
  {
    path: '/custom-plugin',
    name: 'CustomPlugin',
    component: Layout,
    meta: { title: '自定义插件' },
    children: [
      {
        path: 'sliding-clock',
        name: 'SlidingClock',
        component: () => import('@/views/custom-plugin/sliding-clock/index'),
        meta: { title: '滑动时钟' }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
