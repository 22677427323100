import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon'// svg component

// register globally
Vue.component('svg-icon', SvgIcon)

/**
 * @constant
 * require.context
 * @param {String} directory：说明需要检索的目录
 * @param {Boolean} useSubdirectories：是否检索子目录
 * @param {RegExp} regExp: 匹配文件的正则表达式
 */
const req = require.context('./svg', true, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
