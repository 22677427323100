<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" />
        <hello-world msg="Welcome" /> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
