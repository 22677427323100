import Cookies from 'js-cookie'

const TokenKey = 'LSR_Excel_Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function refreshToken() {
  const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000)
  return Cookies.set(TokenKey, getToken(), { expires: inThirtyMinutes })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
