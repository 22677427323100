/**
 * Created by LiuShengRong on 2020/06/08.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string|Number} str 数值
 * @param {Number} num 几位小数
 * @returns {Boolean}
 */
export function isFloat(str, num = 2) {
  if (!str) {
    return false
  }
  if (typeof str === 'number') {
    str = str.toString()
  }
  if (num === 0) {
    return /^[1-9]\d*$/.test(str.trim())
  } else {
    const floatRule = new RegExp('^\\d+(\\.\\d{1,' + num + '})?$')
    return floatRule.test(str.trim())
  }
}
