// import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { users } from '@/utils/user'
import router, { resetRouter } from '@/routers'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    roles: []
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_CENTRE_WAREHOUSE: (state, centreWarehouseList) => {
    state.centreWarehouseList = centreWarehouseList
  },
  SET_CURRENT_CENTRE_WAREHOUSE: (state, Warehouse) => {
    state.currentCentreWarehouse = Warehouse
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      let token = ''
      const loginCode = users.some(item => {
        if (username === item.name && password === item.password) {
          token = item.token
          return true
        } else {
          return false
        }
      })
      setTimeout(() => {
        if (loginCode) {
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        } else {
          reject({ msg: '账号密码错误' })
        }
      }, 500)
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      let userInfo = {}
      const userCode = users.some(item => {
        if (state.token === item.token) {
          userInfo = item
          return true
        } else {
          return false
        }
      })
      setTimeout(() => {
        if (userCode) {
          commit('SET_ROLES', userInfo.roles)
          commit('SET_NAME', userInfo.name)
          resolve(userInfo)
        } else {
          reject({ msg: '用户信息异常' })
        }
      }, 500)
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }, 500)
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      resetRouter()

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

