import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // 数据源
  state: {
    count: 0,
    users: [
      { id: 0, name: 'aaaaa' },
      { id: 1, name: 'sssss' },
      { id: 2, name: 'ddddd' },
      { id: 3, name: 'fffff' },
      { id: 4, name: 'ggggg' }
    ]
  },
  // state的计算属性
  getters: {
    doubleCount: state => {
      return state.count * 2
    },
    // 接受第二个getters参数，使用其他getter
    doubleCountNext: (state, getters) => {
      return getters.doubleCount + 1
    },
    // return一个方法，实现传参，可用于搜索
    getUserById: (state) => (id) => {
      return state.users.find(user => user.id === id)
    },
    ...getters
  },
  // 变化 命名使用常量 全大写 mutation 必须是同步函数
  mutations: {
    ADD_COUNT: (state) => {
      state.count++
    },
    // 带参数 使用 this.$store.commit('addCount', payload) 调用，
    // or this.$state.commit({ type: 'addCount', n: 10})
    // payload 尽量为一个对象 比如 { n: 10}
    ADD_COUNT_N: (state, payload) => {
      state.count += payload.n
    }
  },
  // 用来提交 mutation， 可异步，使用 store.dispatch
  // 使用 传参同 mutation
  actions: {
    addCountAsync(context, payload) {
      context.commit('ADD_COUNT')
      setTimeout(() => {
        context.commit('ADD_COUNT_N', payload)
      }, 2000)
    },
    // 返回promise，可以链式调用 store.dispatch().then()
    promiseAction({ commit }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          commit('ADD_COUNT')
          resolve()
        }, 1000)
      })
    }
    // or 参数解构
    // addCount({ commit,state }, payload) {
    //     commit('ADD_COUNT')
    // }
  }
})

export default store
