<template>
  <div v-if="!isHome" class="page-title-container">
    <breadcrumb class="breadcrumb-container" />
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

export default {
  name: 'PageTitleBreadcrumb',
  components: {
    Breadcrumb
  },
  computed: {
    pageTitle() {
      return this.$route.meta.title
    },
    isHome() {
      return this.$route.path === '/home'
    }
  }
}
</script>
<style lang="scss" scoped>
.page-title-container {
    padding: 15px 40px;
    height: 53px;
    border-bottom: 1px solid #eeeeee;
}
</style>
