<template>
  <el-menu
    :default-active="activeMenu"
    :unique-opened="true"
    :collapse-transition="false"
    :text-color="variables.menuText"
    :active-text-color="variables.menuActiveText"
    menu-trigger="hover"
    mode="horizontal"
    class="custom-nav-menu"
    router
  >
    <template v-for="route in menuRouters">
      <nav-menu-item :key="route.path" :menu-item="route" :base-path="route.path"></nav-menu-item>
    </template>
  </el-menu>
</template>
<script>
import { constantRoutes } from '@/routers'
import variables from '@/styles/variables.scss'
import NavMenuItem from './NavMenuItem'

export default {
  name: 'NavMenu',
  components: {
    NavMenuItem
  },
  data() {
    return {}
  },
  computed: {
    variables() {
      return variables
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    menuRouters() {
      return constantRoutes
    }
  }
}
</script>
