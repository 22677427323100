<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="horizontal"
      >
        <!-- :collapse="isCollapse" -->
        <sidebar-item v-for="route in menuRouters" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
      <!-- <hamburger :is-active="sidebar.opened" @toggleClick="toggleSideBar" /> -->
      <!-- class="hamburger-container" -->
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import Hamburger from '@/components/Hamburger'
import SidebarItem from './SidebarItem'
import { constantRoutes } from '@/routers'
import variables from '@/styles/variables.scss'

export default {
  components: {
    SidebarItem
    // Hamburger
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    menuRouters() {
      return constantRoutes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>
<style lang="scss" scoped>
.hamburger-container {
    line-height: 50px;
    position: absolute;
    left: 2px;
    bottom: 30px;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
        background: rgba(0, 0, 0, .025)
    }
}
</style>
