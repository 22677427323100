<template>
  <component :is="tagName" v-if="!menuItem.hidden" :ref="isMenuItem?'subMenu':'menuItem'" :index="resolvePath(actualMenuItem.path)" :popper-append-to-body="isMenuItem">
    <!--
            handleMouseenter无限调用，内存溢出问题
            submenu外加 div 或者 popper-append-to-body设置为false 或者 hover 改为 click
        -->
    <template v-if="isMenuItem">
      <svg-icon v-if="actualMenuItem.meta && actualMenuItem.meta.icon" :icon-class="actualMenuItem.meta.icon" class="menu-icon" />
      <span>{{ actualMenuItem.meta && actualMenuItem.meta.title }}</span>
    </template>
    <template v-else>
      <template slot="title">
        <svg-icon v-if="menuItem.meta && menuItem.meta.icon" :icon-class="menuItem.meta.icon" class="menu-icon" />
        <span>{{ menuItem.meta && menuItem.meta.title }}</span>
      </template>
      <nav-menu-item
        v-for="child in menuItem.children"
        :key="menuItem.path + child.path"
        :menu-item="child"
        :base-path="resolvePath(child.path)"
      ></nav-menu-item>
    </template>
  </component>
</template>
<script>
import { isExternal } from '@/utils/validate'
import FixiOSBug from './FixiOSBug'
import path from 'path'

export default {
  name: 'NavMenuItem',
  mixins: [FixiOSBug],
  props: {
    menuItem: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showingChildren: [],
      actualMenuItem: {}
    }
  },
  computed: {
    isMenuItem() {
      return (this.showingChildren.length === 1 || this.showingChildren.length === 0) &&
                (!this.actualMenuItem.children || this.actualMenuItem.noShowingChildren) &&
                !this.menuItem.alwaysShow
    },
    tagName() {
      return this.isMenuItem ? 'el-menu-item' : 'el-submenu'
    }
  },
  created() {
    if (!this.menuItem.hidden) {
      if (this.menuItem.children) {
        this.showingChildren = this.menuItem.children.filter(item => {
          return !item.hidden
        }) || []
      }
      if (this.showingChildren.length > 0) {
        this.actualMenuItem = this.showingChildren[this.showingChildren.length - 1]
      } else {
        this.actualMenuItem = { ...this.menuItem, path: '', noShowingChildren: true }
      }
    }
  },
  methods: {
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
