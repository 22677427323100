export const users = [
  {
    name: 'admin',
    password: 'Aa111111',
    roles: ['admin'],
    token: 'admin123456789'
  },
  {
    name: '林',
    password: 'LQZ456456',
    roles: ['lin'],
    token: 'lin123456789'
  }
]
